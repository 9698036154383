
.App-container {
  background-image: url("https://i.ytimg.com/vi/v2iWow1QFtk/maxresdefault.jpg");
  /* background-color: white; */
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.top-element{
  margin-bottom: 20px;
  color:white;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}


.principal-container{
  display:flex;
  justify-content: center;
  background: #403c3c24;
  padding: 20px;
  width:95%;
  color: white;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

}
.principal-container .left-side{
  justify-content: center;

}

.principal-container .right-side{
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: center;
}

.principal-container .right-side h1,p{
  margin-top:10px;
  margin-bottom:0px;
  text-align: center;
}

.qr-sm{
  display:inline;
}
.qr-lg{
  display:none;
}

@media (min-width: 800px) { 

  .principal-container{
    width:70%;
    flex-wrap: nowrap;
  }


}

@media (min-width: 400px) { 

  .qr-sm{
    display:none;
  }
  .qr-lg{
    display:inline;
  }


}

